/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { InventoryVideo } from './InventoryVideo';
import {
    InventoryVideoFromJSON,
    InventoryVideoFromJSONTyped,
    InventoryVideoToJSON,
} from './InventoryVideo';

/**
 * 
 * @export
 * @interface InventoryVideoSearchItem
 */
export interface InventoryVideoSearchItem {
    /**
     * 
     * @type {string}
     * @memberof InventoryVideoSearchItem
     */
    branchName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InventoryVideoSearchItem
     */
    dealerId?: number;
    /**
     * 
     * @type {number}
     * @memberof InventoryVideoSearchItem
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof InventoryVideoSearchItem
     */
    headline?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof InventoryVideoSearchItem
     */
    createdAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof InventoryVideoSearchItem
     */
    price?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InventoryVideoSearchItem
     */
    imageId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InventoryVideoSearchItem
     */
    image?: string | null;
    /**
     * 
     * @type {Array<InventoryVideo>}
     * @memberof InventoryVideoSearchItem
     */
    videos?: Array<InventoryVideo> | null;
}

/**
 * Check if a given object implements the InventoryVideoSearchItem interface.
 */
export function instanceOfInventoryVideoSearchItem(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InventoryVideoSearchItemFromJSON(json: any): InventoryVideoSearchItem {
    return InventoryVideoSearchItemFromJSONTyped(json, false);
}

export function InventoryVideoSearchItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryVideoSearchItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'branchName': !exists(json, 'branchName') ? undefined : json['branchName'],
        'dealerId': !exists(json, 'dealerId') ? undefined : json['dealerId'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'headline': !exists(json, 'headline') ? undefined : json['headline'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'price': !exists(json, 'price') ? undefined : json['price'],
        'imageId': !exists(json, 'imageId') ? undefined : json['imageId'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'videos': !exists(json, 'videos') ? undefined : (json['videos'] === null ? null : (json['videos'] as Array<any>).map(InventoryVideoFromJSON)),
    };
}

export function InventoryVideoSearchItemToJSON(value?: InventoryVideoSearchItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'branchName': value.branchName,
        'dealerId': value.dealerId,
        'id': value.id,
        'headline': value.headline,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'price': value.price,
        'imageId': value.imageId,
        'image': value.image,
        'videos': value.videos === undefined ? undefined : (value.videos === null ? null : (value.videos as Array<any>).map(InventoryVideoToJSON)),
    };
}

