/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ClassifiedItemAttachment } from './ClassifiedItemAttachment';
import {
    ClassifiedItemAttachmentFromJSON,
    ClassifiedItemAttachmentFromJSONTyped,
    ClassifiedItemAttachmentToJSON,
} from './ClassifiedItemAttachment';
import type { ClassifiedItemHighlight } from './ClassifiedItemHighlight';
import {
    ClassifiedItemHighlightFromJSON,
    ClassifiedItemHighlightFromJSONTyped,
    ClassifiedItemHighlightToJSON,
} from './ClassifiedItemHighlight';
import type { ClassifiedItemOwner } from './ClassifiedItemOwner';
import {
    ClassifiedItemOwnerFromJSON,
    ClassifiedItemOwnerFromJSONTyped,
    ClassifiedItemOwnerToJSON,
} from './ClassifiedItemOwner';
import type { ClassifiedItemSpecification } from './ClassifiedItemSpecification';
import {
    ClassifiedItemSpecificationFromJSON,
    ClassifiedItemSpecificationFromJSONTyped,
    ClassifiedItemSpecificationToJSON,
} from './ClassifiedItemSpecification';
import type { ClassifiedItemStatData } from './ClassifiedItemStatData';
import {
    ClassifiedItemStatDataFromJSON,
    ClassifiedItemStatDataFromJSONTyped,
    ClassifiedItemStatDataToJSON,
} from './ClassifiedItemStatData';
import type { ClassifiedItemSystemData } from './ClassifiedItemSystemData';
import {
    ClassifiedItemSystemDataFromJSON,
    ClassifiedItemSystemDataFromJSONTyped,
    ClassifiedItemSystemDataToJSON,
} from './ClassifiedItemSystemData';

/**
 * 
 * @export
 * @interface ClassifiedItem
 */
export interface ClassifiedItem {
    /**
     * 
     * @type {number}
     * @memberof ClassifiedItem
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ClassifiedItem
     */
    url?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassifiedItem
     */
    headLine?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassifiedItem
     */
    make?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassifiedItem
     */
    model?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassifiedItem
     */
    modelGroup?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassifiedItem
     */
    variant?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassifiedItem
     */
    alternativeVariant?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassifiedItem
     */
    strippedVariant?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassifiedItem
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassifiedItem
     */
    priceLabel?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassifiedItem
     */
    priceFormatted?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ClassifiedItem
     */
    mileage?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ClassifiedItem
     */
    year?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassifiedItem
     */
    gearType?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ClassifiedItem
     */
    readonly kml?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ClassifiedItem
     */
    horsepower?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ClassifiedItem
     */
    transmission?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassifiedItem
     */
    drivetrain?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassifiedItem
     */
    fueltype?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ClassifiedItem
     */
    batteryCapacity?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ClassifiedItem
     */
    distance?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ClassifiedItem
     */
    yearOfManufacture?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ClassifiedItem
     */
    yearOfRegistration?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ClassifiedItem
     */
    monthOfRegistration?: number | null;
    /**
     * 
     * @type {ClassifiedItemSystemData}
     * @memberof ClassifiedItem
     */
    systemData?: ClassifiedItemSystemData;
    /**
     * 
     * @type {ClassifiedItemStatData}
     * @memberof ClassifiedItem
     */
    statData?: ClassifiedItemStatData;
    /**
     * 
     * @type {ClassifiedItemOwner}
     * @memberof ClassifiedItem
     */
    owner?: ClassifiedItemOwner;
    /**
     * 
     * @type {Array<ClassifiedItemAttachment>}
     * @memberof ClassifiedItem
     */
    images?: Array<ClassifiedItemAttachment> | null;
    /**
     * 
     * @type {Array<ClassifiedItemAttachment>}
     * @memberof ClassifiedItem
     */
    videos?: Array<ClassifiedItemAttachment> | null;
    /**
     * 
     * @type {Array<ClassifiedItemSpecification>}
     * @memberof ClassifiedItem
     */
    specifications?: Array<ClassifiedItemSpecification> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ClassifiedItem
     */
    equipment?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof ClassifiedItem
     */
    maxSpeed?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ClassifiedItem
     */
    color?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassifiedItem
     */
    bodyStyle?: string | null;
    /**
     * 
     * @type {Array<ClassifiedItemSpecification>}
     * @memberof ClassifiedItem
     */
    leasingSpecifications?: Array<ClassifiedItemSpecification> | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClassifiedItem
     */
    disableWriteTo?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClassifiedItem
     */
    isNew?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClassifiedItem
     */
    hasGuarantee?: boolean;
    /**
     * 
     * @type {Array<ClassifiedItemHighlight>}
     * @memberof ClassifiedItem
     */
    highlights?: Array<ClassifiedItemHighlight> | null;
    /**
     * 
     * @type {number}
     * @memberof ClassifiedItem
     */
    previousMaxPrice?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ClassifiedItem
     */
    changeInMaxPrice?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ClassifiedItem
     */
    leasingDuration?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassifiedItem
     */
    leasingDownPaymentFormatted?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassifiedItem
     */
    leasingResidualValueFormatted?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassifiedItem
     */
    leasingMileagePerYear?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ClassifiedItem
     */
    firstRegDate?: Date | null;
    /**
     * 
     * @type {Array<ClassifiedItemAttachment>}
     * @memberof ClassifiedItem
     */
    externalVideos?: Array<ClassifiedItemAttachment> | null;
}

/**
 * Check if a given object implements the ClassifiedItem interface.
 */
export function instanceOfClassifiedItem(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ClassifiedItemFromJSON(json: any): ClassifiedItem {
    return ClassifiedItemFromJSONTyped(json, false);
}

export function ClassifiedItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClassifiedItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'headLine': !exists(json, 'headLine') ? undefined : json['headLine'],
        'make': !exists(json, 'make') ? undefined : json['make'],
        'model': !exists(json, 'model') ? undefined : json['model'],
        'modelGroup': !exists(json, 'modelGroup') ? undefined : json['modelGroup'],
        'variant': !exists(json, 'variant') ? undefined : json['variant'],
        'alternativeVariant': !exists(json, 'alternativeVariant') ? undefined : json['alternativeVariant'],
        'strippedVariant': !exists(json, 'strippedVariant') ? undefined : json['strippedVariant'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'priceLabel': !exists(json, 'priceLabel') ? undefined : json['priceLabel'],
        'priceFormatted': !exists(json, 'priceFormatted') ? undefined : json['priceFormatted'],
        'mileage': !exists(json, 'mileage') ? undefined : json['mileage'],
        'year': !exists(json, 'year') ? undefined : json['year'],
        'gearType': !exists(json, 'gearType') ? undefined : json['gearType'],
        'kml': !exists(json, 'kml') ? undefined : json['kml'],
        'horsepower': !exists(json, 'horsepower') ? undefined : json['horsepower'],
        'transmission': !exists(json, 'transmission') ? undefined : json['transmission'],
        'drivetrain': !exists(json, 'drivetrain') ? undefined : json['drivetrain'],
        'fueltype': !exists(json, 'fueltype') ? undefined : json['fueltype'],
        'batteryCapacity': !exists(json, 'batteryCapacity') ? undefined : json['batteryCapacity'],
        'distance': !exists(json, 'distance') ? undefined : json['distance'],
        'yearOfManufacture': !exists(json, 'yearOfManufacture') ? undefined : json['yearOfManufacture'],
        'yearOfRegistration': !exists(json, 'yearOfRegistration') ? undefined : json['yearOfRegistration'],
        'monthOfRegistration': !exists(json, 'monthOfRegistration') ? undefined : json['monthOfRegistration'],
        'systemData': !exists(json, 'systemData') ? undefined : ClassifiedItemSystemDataFromJSON(json['systemData']),
        'statData': !exists(json, 'statData') ? undefined : ClassifiedItemStatDataFromJSON(json['statData']),
        'owner': !exists(json, 'owner') ? undefined : ClassifiedItemOwnerFromJSON(json['owner']),
        'images': !exists(json, 'images') ? undefined : (json['images'] === null ? null : (json['images'] as Array<any>).map(ClassifiedItemAttachmentFromJSON)),
        'videos': !exists(json, 'videos') ? undefined : (json['videos'] === null ? null : (json['videos'] as Array<any>).map(ClassifiedItemAttachmentFromJSON)),
        'specifications': !exists(json, 'specifications') ? undefined : (json['specifications'] === null ? null : (json['specifications'] as Array<any>).map(ClassifiedItemSpecificationFromJSON)),
        'equipment': !exists(json, 'equipment') ? undefined : json['equipment'],
        'maxSpeed': !exists(json, 'maxSpeed') ? undefined : json['maxSpeed'],
        'color': !exists(json, 'color') ? undefined : json['color'],
        'bodyStyle': !exists(json, 'bodyStyle') ? undefined : json['bodyStyle'],
        'leasingSpecifications': !exists(json, 'leasingSpecifications') ? undefined : (json['leasingSpecifications'] === null ? null : (json['leasingSpecifications'] as Array<any>).map(ClassifiedItemSpecificationFromJSON)),
        'disableWriteTo': !exists(json, 'disableWriteTo') ? undefined : json['disableWriteTo'],
        'isNew': !exists(json, 'isNew') ? undefined : json['isNew'],
        'hasGuarantee': !exists(json, 'hasGuarantee') ? undefined : json['hasGuarantee'],
        'highlights': !exists(json, 'highlights') ? undefined : (json['highlights'] === null ? null : (json['highlights'] as Array<any>).map(ClassifiedItemHighlightFromJSON)),
        'previousMaxPrice': !exists(json, 'previousMaxPrice') ? undefined : json['previousMaxPrice'],
        'changeInMaxPrice': !exists(json, 'changeInMaxPrice') ? undefined : json['changeInMaxPrice'],
        'leasingDuration': !exists(json, 'leasingDuration') ? undefined : json['leasingDuration'],
        'leasingDownPaymentFormatted': !exists(json, 'leasingDownPaymentFormatted') ? undefined : json['leasingDownPaymentFormatted'],
        'leasingResidualValueFormatted': !exists(json, 'leasingResidualValueFormatted') ? undefined : json['leasingResidualValueFormatted'],
        'leasingMileagePerYear': !exists(json, 'leasingMileagePerYear') ? undefined : json['leasingMileagePerYear'],
        'firstRegDate': !exists(json, 'firstRegDate') ? undefined : (json['firstRegDate'] === null ? null : new Date(json['firstRegDate'])),
        'externalVideos': !exists(json, 'externalVideos') ? undefined : (json['externalVideos'] === null ? null : (json['externalVideos'] as Array<any>).map(ClassifiedItemAttachmentFromJSON)),
    };
}

export function ClassifiedItemToJSON(value?: ClassifiedItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'url': value.url,
        'headLine': value.headLine,
        'make': value.make,
        'model': value.model,
        'modelGroup': value.modelGroup,
        'variant': value.variant,
        'alternativeVariant': value.alternativeVariant,
        'strippedVariant': value.strippedVariant,
        'description': value.description,
        'priceLabel': value.priceLabel,
        'priceFormatted': value.priceFormatted,
        'mileage': value.mileage,
        'year': value.year,
        'gearType': value.gearType,
        'horsepower': value.horsepower,
        'transmission': value.transmission,
        'drivetrain': value.drivetrain,
        'fueltype': value.fueltype,
        'batteryCapacity': value.batteryCapacity,
        'distance': value.distance,
        'yearOfManufacture': value.yearOfManufacture,
        'yearOfRegistration': value.yearOfRegistration,
        'monthOfRegistration': value.monthOfRegistration,
        'systemData': ClassifiedItemSystemDataToJSON(value.systemData),
        'statData': ClassifiedItemStatDataToJSON(value.statData),
        'owner': ClassifiedItemOwnerToJSON(value.owner),
        'images': value.images === undefined ? undefined : (value.images === null ? null : (value.images as Array<any>).map(ClassifiedItemAttachmentToJSON)),
        'videos': value.videos === undefined ? undefined : (value.videos === null ? null : (value.videos as Array<any>).map(ClassifiedItemAttachmentToJSON)),
        'specifications': value.specifications === undefined ? undefined : (value.specifications === null ? null : (value.specifications as Array<any>).map(ClassifiedItemSpecificationToJSON)),
        'equipment': value.equipment,
        'maxSpeed': value.maxSpeed,
        'color': value.color,
        'bodyStyle': value.bodyStyle,
        'leasingSpecifications': value.leasingSpecifications === undefined ? undefined : (value.leasingSpecifications === null ? null : (value.leasingSpecifications as Array<any>).map(ClassifiedItemSpecificationToJSON)),
        'disableWriteTo': value.disableWriteTo,
        'isNew': value.isNew,
        'hasGuarantee': value.hasGuarantee,
        'highlights': value.highlights === undefined ? undefined : (value.highlights === null ? null : (value.highlights as Array<any>).map(ClassifiedItemHighlightToJSON)),
        'previousMaxPrice': value.previousMaxPrice,
        'changeInMaxPrice': value.changeInMaxPrice,
        'leasingDuration': value.leasingDuration,
        'leasingDownPaymentFormatted': value.leasingDownPaymentFormatted,
        'leasingResidualValueFormatted': value.leasingResidualValueFormatted,
        'leasingMileagePerYear': value.leasingMileagePerYear,
        'firstRegDate': value.firstRegDate === undefined ? undefined : (value.firstRegDate === null ? null : value.firstRegDate.toISOString()),
        'externalVideos': value.externalVideos === undefined ? undefined : (value.externalVideos === null ? null : (value.externalVideos as Array<any>).map(ClassifiedItemAttachmentToJSON)),
    };
}

