import { AuthApi, Middleware, SignInResponse } from '../lib-api';
import { useMemo } from 'react';
import { defaultApiConfiguration } from './use-api';
import {
  getPerssistToken,
  PerssistToken,
  setPerssistToken,
} from 'src/contexts/auth/jwt-context';

let mutex: Promise<SignInResponse> | null = null;

function defer<T>() {
  var deferred: { promise: Promise<T>; resolve: (value: T) => void } = {
    promise: null!,
    resolve: null!,
  };
  deferred.promise = new Promise((resolve) => {
    deferred.resolve = resolve;
  });
  return deferred;
}

const useMiddleware = () => {
  const token = getPerssistToken();
  const middleware: Middleware = useMemo(() => {
    return {
      post: async (context) => {
        if (
          context.response.status === 401 &&
          context.response.headers.has('token-expired')
        ) {
          const token = getPerssistToken();
          if (!token?.accessToken) {
            return;
          }

          if (mutex == null) {
            let deferred = defer<SignInResponse>();
            mutex = deferred.promise;
            const authApi = new AuthApi(defaultApiConfiguration);
            try {
              const refreshResponse = await authApi.refreshToken({
                accessToken: token?.accessToken,
                refreshToken: token?.refreshToken,
              });
              var new_token: PerssistToken = {
                accessToken: refreshResponse.accessToken!,
                refreshToken: refreshResponse.refreshToken!,
                expirationTime: refreshResponse.expirationTime!,
              };
              setPerssistToken(new_token);
              // dispatch(login(refreshResponse));
              deferred.resolve(refreshResponse);
              mutex = null;
              context.init.headers = {
                ...context.init.headers,
                Authorization: `Bearer ${refreshResponse.accessToken!}`,
              };
            } catch (e) {
              // dispatch(logout());
            }
          } else {
            try {
              let refreshResponse = await mutex;
              context.init.headers = {
                ...context.init.headers,
                Authorization: `Bearer ${refreshResponse.accessToken!}`,
              };
            } catch (e) {
              // dispatch({ type: 'logout' });
            }
          }
          return await context.fetch(context.url, context.init);
        } else if (context.response.status === 403) {
          //console.log('403');
        } else if (
          context.response.status === 404 ||
          context.response.status === 400
        ) {
          //console.log('400/404');
        }
      },
    };
  }, []);

  return {
    accessToken: token?.accessToken,
    middleware,
  };
};

export default useMiddleware;
