/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Bar,
  DateTimeChartSerie,
  RatingAverageResponse,
} from '../models';
import {
    BarFromJSON,
    BarToJSON,
    DateTimeChartSerieFromJSON,
    DateTimeChartSerieToJSON,
    RatingAverageResponseFromJSON,
    RatingAverageResponseToJSON,
} from '../models';

export interface ActiveCountRequest {
    dealerIds?: Array<number>;
    startDate?: Date;
    endDate?: Date;
}

export interface ActiveFavoritesCountRequest {
    dealerIds?: Array<number>;
    startDate?: Date;
    endDate?: Date;
}

export interface CallTrackingAnsweringChartRequest {
    dealerIds?: Array<number>;
    startDate?: Date;
    endDate?: Date;
}

export interface CallTrackingChartRequest {
    dealerIds?: Array<number>;
    startDate?: Date;
    endDate?: Date;
}

export interface ClickFacebookChartRequest {
    dealerIds?: Array<number>;
    startDate?: Date;
    endDate?: Date;
}

export interface CombinedLeadsChartRequest {
    dealerIds?: Array<number>;
    startDate?: Date;
    endDate?: Date;
}

export interface IwFacebookChartRequest {
    dealerIds?: Array<number>;
    startDate?: Date;
    endDate?: Date;
}

export interface IwFacebookClickCountRequest {
    dealerIds?: Array<number>;
    startDate?: Date;
    endDate?: Date;
}

export interface IwFacebookImpressionCountRequest {
    dealerIds?: Array<number>;
    startDate?: Date;
    endDate?: Date;
}

export interface LeadCountRequest {
    dealerIds?: Array<number>;
    startDate?: Date;
    endDate?: Date;
}

export interface NewCountRequest {
    dealerIds?: Array<number>;
    startDate?: Date;
    endDate?: Date;
}

export interface PriceChangeCountRequest {
    dealerIds?: Array<number>;
    startDate?: Date;
    endDate?: Date;
}

export interface RatingAverageRequest {
    dealerIds?: Array<number>;
    startDate?: Date;
    endDate?: Date;
}

export interface SoldCountRequest {
    dealerIds?: Array<number>;
    startDate?: Date;
    endDate?: Date;
}

export interface TestdriveleadCountRequest {
    dealerIds?: Array<number>;
    startDate?: Date;
    endDate?: Date;
}

export interface VipViewCountRequest {
    dealerIds?: Array<number>;
    startDate?: Date;
    endDate?: Date;
}

export interface VipViewsChartRequest {
    dealerIds?: Array<number>;
    startDate?: Date;
    endDate?: Date;
}

/**
 * 
 */
export class StatApi extends runtime.BaseAPI {

    /**
     */
    async activeCountRaw(requestParameters: ActiveCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters.dealerIds) {
            queryParameters['DealerIds'] = requestParameters.dealerIds;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer-portal/v1/stat/active-count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async activeCount(requestParameters: ActiveCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.activeCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async activeFavoritesCountRaw(requestParameters: ActiveFavoritesCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters.dealerIds) {
            queryParameters['DealerIds'] = requestParameters.dealerIds;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer-portal/v1/stat/active-favorites-count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async activeFavoritesCount(requestParameters: ActiveFavoritesCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.activeFavoritesCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async callTrackingAnsweringChartRaw(requestParameters: CallTrackingAnsweringChartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Bar>> {
        const queryParameters: any = {};

        if (requestParameters.dealerIds) {
            queryParameters['DealerIds'] = requestParameters.dealerIds;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer-portal/v1/stat/call-tracking-answering-chart`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BarFromJSON(jsonValue));
    }

    /**
     */
    async callTrackingAnsweringChart(requestParameters: CallTrackingAnsweringChartRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Bar> {
        const response = await this.callTrackingAnsweringChartRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async callTrackingChartRaw(requestParameters: CallTrackingChartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DateTimeChartSerie>>> {
        const queryParameters: any = {};

        if (requestParameters.dealerIds) {
            queryParameters['DealerIds'] = requestParameters.dealerIds;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer-portal/v1/stat/call-trackings-chart`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DateTimeChartSerieFromJSON));
    }

    /**
     */
    async callTrackingChart(requestParameters: CallTrackingChartRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DateTimeChartSerie>> {
        const response = await this.callTrackingChartRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async clickFacebookChartRaw(requestParameters: ClickFacebookChartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DateTimeChartSerie>>> {
        const queryParameters: any = {};

        if (requestParameters.dealerIds) {
            queryParameters['DealerIds'] = requestParameters.dealerIds;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer-portal/v1/stat/click-facebook-chart`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DateTimeChartSerieFromJSON));
    }

    /**
     */
    async clickFacebookChart(requestParameters: ClickFacebookChartRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DateTimeChartSerie>> {
        const response = await this.clickFacebookChartRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async combinedLeadsChartRaw(requestParameters: CombinedLeadsChartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DateTimeChartSerie>>> {
        const queryParameters: any = {};

        if (requestParameters.dealerIds) {
            queryParameters['DealerIds'] = requestParameters.dealerIds;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer-portal/v1/stat/combined-leads-chart`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DateTimeChartSerieFromJSON));
    }

    /**
     */
    async combinedLeadsChart(requestParameters: CombinedLeadsChartRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DateTimeChartSerie>> {
        const response = await this.combinedLeadsChartRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async iwFacebookChartRaw(requestParameters: IwFacebookChartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DateTimeChartSerie>>> {
        const queryParameters: any = {};

        if (requestParameters.dealerIds) {
            queryParameters['DealerIds'] = requestParameters.dealerIds;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer-portal/v1/stat/iw-facebook-chart`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DateTimeChartSerieFromJSON));
    }

    /**
     */
    async iwFacebookChart(requestParameters: IwFacebookChartRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DateTimeChartSerie>> {
        const response = await this.iwFacebookChartRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async iwFacebookClickCountRaw(requestParameters: IwFacebookClickCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters.dealerIds) {
            queryParameters['DealerIds'] = requestParameters.dealerIds;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer-portal/v1/stat/iw-facebook-click-count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async iwFacebookClickCount(requestParameters: IwFacebookClickCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.iwFacebookClickCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async iwFacebookImpressionCountRaw(requestParameters: IwFacebookImpressionCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters.dealerIds) {
            queryParameters['DealerIds'] = requestParameters.dealerIds;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer-portal/v1/stat/iw-facebook-impression-count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async iwFacebookImpressionCount(requestParameters: IwFacebookImpressionCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.iwFacebookImpressionCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async leadCountRaw(requestParameters: LeadCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters.dealerIds) {
            queryParameters['DealerIds'] = requestParameters.dealerIds;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer-portal/v1/stat/lead-count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async leadCount(requestParameters: LeadCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.leadCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async newCountRaw(requestParameters: NewCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters.dealerIds) {
            queryParameters['DealerIds'] = requestParameters.dealerIds;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer-portal/v1/stat/new-count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async newCount(requestParameters: NewCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.newCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async priceChangeCountRaw(requestParameters: PriceChangeCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters.dealerIds) {
            queryParameters['DealerIds'] = requestParameters.dealerIds;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer-portal/v1/stat/price-change-count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async priceChangeCount(requestParameters: PriceChangeCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.priceChangeCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async ratingAverageRaw(requestParameters: RatingAverageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RatingAverageResponse>> {
        const queryParameters: any = {};

        if (requestParameters.dealerIds) {
            queryParameters['DealerIds'] = requestParameters.dealerIds;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer-portal/v1/stat/rating-average`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RatingAverageResponseFromJSON(jsonValue));
    }

    /**
     */
    async ratingAverage(requestParameters: RatingAverageRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RatingAverageResponse> {
        const response = await this.ratingAverageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async soldCountRaw(requestParameters: SoldCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters.dealerIds) {
            queryParameters['DealerIds'] = requestParameters.dealerIds;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer-portal/v1/stat/sold-count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async soldCount(requestParameters: SoldCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.soldCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async testdriveleadCountRaw(requestParameters: TestdriveleadCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters.dealerIds) {
            queryParameters['DealerIds'] = requestParameters.dealerIds;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer-portal/v1/stat/testdrivelead-count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async testdriveleadCount(requestParameters: TestdriveleadCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.testdriveleadCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async vipViewCountRaw(requestParameters: VipViewCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        if (requestParameters.dealerIds) {
            queryParameters['DealerIds'] = requestParameters.dealerIds;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer-portal/v1/stat/vip-view-count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async vipViewCount(requestParameters: VipViewCountRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.vipViewCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async vipViewsChartRaw(requestParameters: VipViewsChartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<DateTimeChartSerie>>> {
        const queryParameters: any = {};

        if (requestParameters.dealerIds) {
            queryParameters['DealerIds'] = requestParameters.dealerIds;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['StartDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['EndDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/dealer-portal/v1/stat/vip-views-chart`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DateTimeChartSerieFromJSON));
    }

    /**
     */
    async vipViewsChart(requestParameters: VipViewsChartRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<DateTimeChartSerie>> {
        const response = await this.vipViewsChartRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
