/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SoldClassifiedSearchItem
 */
export interface SoldClassifiedSearchItem {
    /**
     * 
     * @type {string}
     * @memberof SoldClassifiedSearchItem
     */
    branchName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SoldClassifiedSearchItem
     */
    dealerId?: number;
    /**
     * 
     * @type {number}
     * @memberof SoldClassifiedSearchItem
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SoldClassifiedSearchItem
     */
    headline?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof SoldClassifiedSearchItem
     */
    createdAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof SoldClassifiedSearchItem
     */
    price?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SoldClassifiedSearchItem
     */
    imageId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof SoldClassifiedSearchItem
     */
    image?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SoldClassifiedSearchItem
     */
    emailLeadCount?: number;
    /**
     * 
     * @type {number}
     * @memberof SoldClassifiedSearchItem
     */
    callTrackingLeadCount?: number;
    /**
     * 
     * @type {number}
     * @memberof SoldClassifiedSearchItem
     */
    facebookClicks?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SoldClassifiedSearchItem
     */
    facebookViews?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SoldClassifiedSearchItem
     */
    bilhandelViews?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SoldClassifiedSearchItem
     */
    favouriteCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SoldClassifiedSearchItem
     */
    favouriteWithConsentCount?: number;
    /**
     * 
     * @type {number}
     * @memberof SoldClassifiedSearchItem
     */
    favouriteClaimedCount?: number;
    /**
     * 
     * @type {Date}
     * @memberof SoldClassifiedSearchItem
     */
    soldAt?: Date;
}

/**
 * Check if a given object implements the SoldClassifiedSearchItem interface.
 */
export function instanceOfSoldClassifiedSearchItem(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SoldClassifiedSearchItemFromJSON(json: any): SoldClassifiedSearchItem {
    return SoldClassifiedSearchItemFromJSONTyped(json, false);
}

export function SoldClassifiedSearchItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): SoldClassifiedSearchItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'branchName': !exists(json, 'branchName') ? undefined : json['branchName'],
        'dealerId': !exists(json, 'dealerId') ? undefined : json['dealerId'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'headline': !exists(json, 'headline') ? undefined : json['headline'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'price': !exists(json, 'price') ? undefined : json['price'],
        'imageId': !exists(json, 'imageId') ? undefined : json['imageId'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'emailLeadCount': !exists(json, 'emailLeadCount') ? undefined : json['emailLeadCount'],
        'callTrackingLeadCount': !exists(json, 'callTrackingLeadCount') ? undefined : json['callTrackingLeadCount'],
        'facebookClicks': !exists(json, 'facebookClicks') ? undefined : json['facebookClicks'],
        'facebookViews': !exists(json, 'facebookViews') ? undefined : json['facebookViews'],
        'bilhandelViews': !exists(json, 'bilhandelViews') ? undefined : json['bilhandelViews'],
        'favouriteCount': !exists(json, 'favouriteCount') ? undefined : json['favouriteCount'],
        'favouriteWithConsentCount': !exists(json, 'favouriteWithConsentCount') ? undefined : json['favouriteWithConsentCount'],
        'favouriteClaimedCount': !exists(json, 'favouriteClaimedCount') ? undefined : json['favouriteClaimedCount'],
        'soldAt': !exists(json, 'soldAt') ? undefined : (new Date(json['soldAt'])),
    };
}

export function SoldClassifiedSearchItemToJSON(value?: SoldClassifiedSearchItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'branchName': value.branchName,
        'dealerId': value.dealerId,
        'id': value.id,
        'headline': value.headline,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'price': value.price,
        'imageId': value.imageId,
        'image': value.image,
        'emailLeadCount': value.emailLeadCount,
        'callTrackingLeadCount': value.callTrackingLeadCount,
        'facebookClicks': value.facebookClicks,
        'facebookViews': value.facebookViews,
        'bilhandelViews': value.bilhandelViews,
        'favouriteCount': value.favouriteCount,
        'favouriteWithConsentCount': value.favouriteWithConsentCount,
        'favouriteClaimedCount': value.favouriteClaimedCount,
        'soldAt': value.soldAt === undefined ? undefined : (value.soldAt.toISOString()),
    };
}

