/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  InventoryVideoSearchItemSearchResponse,
  InventoryVideoSearchRequest,
} from '../models';
import {
    InventoryVideoSearchItemSearchResponseFromJSON,
    InventoryVideoSearchItemSearchResponseToJSON,
    InventoryVideoSearchRequestFromJSON,
    InventoryVideoSearchRequestToJSON,
} from '../models';

export interface InventoryVideoRequest {
    inventoryVideoSearchRequest?: InventoryVideoSearchRequest;
}

/**
 * 
 */
export class VideoApi extends runtime.BaseAPI {

    /**
     */
    async inventoryVideoRaw(requestParameters: InventoryVideoRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<InventoryVideoSearchItemSearchResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        const response = await this.request({
            path: `/api/dealer-portal/v1/video/inventory-video`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InventoryVideoSearchRequestToJSON(requestParameters.inventoryVideoSearchRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InventoryVideoSearchItemSearchResponseFromJSON(jsonValue));
    }

    /**
     */
    async inventoryVideo(requestParameters: InventoryVideoRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<InventoryVideoSearchItemSearchResponse> {
        const response = await this.inventoryVideoRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
