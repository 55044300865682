/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InventoryVideo
 */
export interface InventoryVideo {
    /**
     * 
     * @type {Date}
     * @memberof InventoryVideo
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InventoryVideo
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof InventoryVideo
     */
    url?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InventoryVideo
     */
    duration?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InventoryVideo
     */
    width?: number;
    /**
     * 
     * @type {number}
     * @memberof InventoryVideo
     */
    height?: number;
}

/**
 * Check if a given object implements the InventoryVideo interface.
 */
export function instanceOfInventoryVideo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InventoryVideoFromJSON(json: any): InventoryVideo {
    return InventoryVideoFromJSONTyped(json, false);
}

export function InventoryVideoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InventoryVideo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'url': !exists(json, 'url') ? undefined : json['url'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'width': !exists(json, 'width') ? undefined : json['width'],
        'height': !exists(json, 'height') ? undefined : json['height'],
    };
}

export function InventoryVideoToJSON(value?: InventoryVideo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'url': value.url,
        'duration': value.duration,
        'width': value.width,
        'height': value.height,
    };
}

