/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SearchAuctionsFilters } from './SearchAuctionsFilters';
import {
    SearchAuctionsFiltersFromJSON,
    SearchAuctionsFiltersFromJSONTyped,
    SearchAuctionsFiltersToJSON,
} from './SearchAuctionsFilters';
import type { SortDir } from './SortDir';
import {
    SortDirFromJSON,
    SortDirFromJSONTyped,
    SortDirToJSON,
} from './SortDir';

/**
 * 
 * @export
 * @interface SearchThreadsRequest
 */
export interface SearchThreadsRequest {
    /**
     * 
     * @type {Array<number>}
     * @memberof SearchThreadsRequest
     */
    dealerIds?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof SearchThreadsRequest
     */
    dealerId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SearchThreadsRequest
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchThreadsRequest
     */
    rowPerPage?: number;
    /**
     * 
     * @type {SortDir}
     * @memberof SearchThreadsRequest
     */
    sortDir?: SortDir;
    /**
     * 
     * @type {string}
     * @memberof SearchThreadsRequest
     */
    sortBy?: string | null;
    /**
     * 
     * @type {SearchAuctionsFilters}
     * @memberof SearchThreadsRequest
     */
    filters?: SearchAuctionsFilters;
}

/**
 * Check if a given object implements the SearchThreadsRequest interface.
 */
export function instanceOfSearchThreadsRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SearchThreadsRequestFromJSON(json: any): SearchThreadsRequest {
    return SearchThreadsRequestFromJSONTyped(json, false);
}

export function SearchThreadsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchThreadsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dealerIds': !exists(json, 'dealerIds') ? undefined : json['dealerIds'],
        'dealerId': !exists(json, 'dealerId') ? undefined : json['dealerId'],
        'page': !exists(json, 'page') ? undefined : json['page'],
        'rowPerPage': !exists(json, 'rowPerPage') ? undefined : json['rowPerPage'],
        'sortDir': !exists(json, 'sortDir') ? undefined : SortDirFromJSON(json['sortDir']),
        'sortBy': !exists(json, 'sortBy') ? undefined : json['sortBy'],
        'filters': !exists(json, 'filters') ? undefined : SearchAuctionsFiltersFromJSON(json['filters']),
    };
}

export function SearchThreadsRequestToJSON(value?: SearchThreadsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dealerIds': value.dealerIds,
        'dealerId': value.dealerId,
        'page': value.page,
        'rowPerPage': value.rowPerPage,
        'sortDir': SortDirToJSON(value.sortDir),
        'sortBy': value.sortBy,
        'filters': SearchAuctionsFiltersToJSON(value.filters),
    };
}

