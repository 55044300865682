/* tslint:disable */
/* eslint-disable */
/**
 * Dealer Portal API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LeadProductStatus } from './LeadProductStatus';
import {
    LeadProductStatusFromJSON,
    LeadProductStatusFromJSONTyped,
    LeadProductStatusToJSON,
} from './LeadProductStatus';
import type { UserProfileType } from './UserProfileType';
import {
    UserProfileTypeFromJSON,
    UserProfileTypeFromJSONTyped,
    UserProfileTypeToJSON,
} from './UserProfileType';

/**
 * 
 * @export
 * @interface ClassifiedItemOwner
 */
export interface ClassifiedItemOwner {
    /**
     * 
     * @type {number}
     * @memberof ClassifiedItemOwner
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ClassifiedItemOwner
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassifiedItemOwner
     */
    address?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ClassifiedItemOwner
     */
    zipCode?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ClassifiedItemOwner
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassifiedItemOwner
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassifiedItemOwner
     */
    phoneOverride?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ClassifiedItemOwner
     */
    imageUrl?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ClassifiedItemOwner
     */
    latitude?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ClassifiedItemOwner
     */
    longitude?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClassifiedItemOwner
     */
    financingOptedOut?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClassifiedItemOwner
     */
    isLead?: boolean;
    /**
     * 
     * @type {LeadProductStatus}
     * @memberof ClassifiedItemOwner
     */
    leadProductStatus?: LeadProductStatus;
    /**
     * 
     * @type {boolean}
     * @memberof ClassifiedItemOwner
     */
    isDBFUMember?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClassifiedItemOwner
     */
    isAutoTjekMember?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClassifiedItemOwner
     */
    offersToyotaRelax?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClassifiedItemOwner
     */
    offersAutoconcept?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClassifiedItemOwner
     */
    useAutoconceptOnAllVehicles?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClassifiedItemOwner
     */
    offersFragus?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClassifiedItemOwner
     */
    useFragusOnAllVehicles?: boolean | null;
    /**
     * 
     * @type {UserProfileType}
     * @memberof ClassifiedItemOwner
     */
    profileType?: UserProfileType;
    /**
     * 
     * @type {boolean}
     * @memberof ClassifiedItemOwner
     */
    isOpenNow?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClassifiedItemOwner
     */
    partnerSantander?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClassifiedItemOwner
     */
    partnerClever?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClassifiedItemOwner
     */
    partnerOK?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClassifiedItemOwner
     */
    partnerFragus?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClassifiedItemOwner
     */
    partnerCarGarantie?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClassifiedItemOwner
     */
    partnerCodan?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClassifiedItemOwner
     */
    testDriveEnabled?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClassifiedItemOwner
     */
    testDriveAsPrimaryCTA?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClassifiedItemOwner
     */
    testDriveAsCTA?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClassifiedItemOwner
     */
    writeEnabled?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClassifiedItemOwner
     */
    writeAsPrimaryCTA?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClassifiedItemOwner
     */
    writeAsCTA?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ClassifiedItemOwner
     */
    writeCTAButtonText?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClassifiedItemOwner
     */
    callEnabled?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClassifiedItemOwner
     */
    callAsPrimaryCTA?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClassifiedItemOwner
     */
    callAsCTA?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClassifiedItemOwner
     */
    leasingOfferEnabled?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClassifiedItemOwner
     */
    leasingOfferAsPrimaryCTA?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClassifiedItemOwner
     */
    leasingOfferAsCTA?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClassifiedItemOwner
     */
    tradeEnabled?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ClassifiedItemOwner
     */
    seezEnabled?: boolean;
}

/**
 * Check if a given object implements the ClassifiedItemOwner interface.
 */
export function instanceOfClassifiedItemOwner(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ClassifiedItemOwnerFromJSON(json: any): ClassifiedItemOwner {
    return ClassifiedItemOwnerFromJSONTyped(json, false);
}

export function ClassifiedItemOwnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClassifiedItemOwner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'zipCode': !exists(json, 'zipCode') ? undefined : json['zipCode'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'phoneOverride': !exists(json, 'phoneOverride') ? undefined : json['phoneOverride'],
        'imageUrl': !exists(json, 'imageUrl') ? undefined : json['imageUrl'],
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
        'financingOptedOut': !exists(json, 'financingOptedOut') ? undefined : json['financingOptedOut'],
        'isLead': !exists(json, 'isLead') ? undefined : json['isLead'],
        'leadProductStatus': !exists(json, 'leadProductStatus') ? undefined : LeadProductStatusFromJSON(json['leadProductStatus']),
        'isDBFUMember': !exists(json, 'isDBFUMember') ? undefined : json['isDBFUMember'],
        'isAutoTjekMember': !exists(json, 'isAutoTjekMember') ? undefined : json['isAutoTjekMember'],
        'offersToyotaRelax': !exists(json, 'offersToyotaRelax') ? undefined : json['offersToyotaRelax'],
        'offersAutoconcept': !exists(json, 'offersAutoconcept') ? undefined : json['offersAutoconcept'],
        'useAutoconceptOnAllVehicles': !exists(json, 'useAutoconceptOnAllVehicles') ? undefined : json['useAutoconceptOnAllVehicles'],
        'offersFragus': !exists(json, 'offersFragus') ? undefined : json['offersFragus'],
        'useFragusOnAllVehicles': !exists(json, 'useFragusOnAllVehicles') ? undefined : json['useFragusOnAllVehicles'],
        'profileType': !exists(json, 'profileType') ? undefined : UserProfileTypeFromJSON(json['profileType']),
        'isOpenNow': !exists(json, 'isOpenNow') ? undefined : json['isOpenNow'],
        'partnerSantander': !exists(json, 'partnerSantander') ? undefined : json['partnerSantander'],
        'partnerClever': !exists(json, 'partnerClever') ? undefined : json['partnerClever'],
        'partnerOK': !exists(json, 'partnerOK') ? undefined : json['partnerOK'],
        'partnerFragus': !exists(json, 'partnerFragus') ? undefined : json['partnerFragus'],
        'partnerCarGarantie': !exists(json, 'partnerCarGarantie') ? undefined : json['partnerCarGarantie'],
        'partnerCodan': !exists(json, 'partnerCodan') ? undefined : json['partnerCodan'],
        'testDriveEnabled': !exists(json, 'testDriveEnabled') ? undefined : json['testDriveEnabled'],
        'testDriveAsPrimaryCTA': !exists(json, 'testDriveAsPrimaryCTA') ? undefined : json['testDriveAsPrimaryCTA'],
        'testDriveAsCTA': !exists(json, 'testDriveAsCTA') ? undefined : json['testDriveAsCTA'],
        'writeEnabled': !exists(json, 'writeEnabled') ? undefined : json['writeEnabled'],
        'writeAsPrimaryCTA': !exists(json, 'writeAsPrimaryCTA') ? undefined : json['writeAsPrimaryCTA'],
        'writeAsCTA': !exists(json, 'writeAsCTA') ? undefined : json['writeAsCTA'],
        'writeCTAButtonText': !exists(json, 'writeCTAButtonText') ? undefined : json['writeCTAButtonText'],
        'callEnabled': !exists(json, 'callEnabled') ? undefined : json['callEnabled'],
        'callAsPrimaryCTA': !exists(json, 'callAsPrimaryCTA') ? undefined : json['callAsPrimaryCTA'],
        'callAsCTA': !exists(json, 'callAsCTA') ? undefined : json['callAsCTA'],
        'leasingOfferEnabled': !exists(json, 'leasingOfferEnabled') ? undefined : json['leasingOfferEnabled'],
        'leasingOfferAsPrimaryCTA': !exists(json, 'leasingOfferAsPrimaryCTA') ? undefined : json['leasingOfferAsPrimaryCTA'],
        'leasingOfferAsCTA': !exists(json, 'leasingOfferAsCTA') ? undefined : json['leasingOfferAsCTA'],
        'tradeEnabled': !exists(json, 'tradeEnabled') ? undefined : json['tradeEnabled'],
        'seezEnabled': !exists(json, 'seezEnabled') ? undefined : json['seezEnabled'],
    };
}

export function ClassifiedItemOwnerToJSON(value?: ClassifiedItemOwner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'address': value.address,
        'zipCode': value.zipCode,
        'city': value.city,
        'phone': value.phone,
        'phoneOverride': value.phoneOverride,
        'imageUrl': value.imageUrl,
        'latitude': value.latitude,
        'longitude': value.longitude,
        'financingOptedOut': value.financingOptedOut,
        'isLead': value.isLead,
        'leadProductStatus': LeadProductStatusToJSON(value.leadProductStatus),
        'isDBFUMember': value.isDBFUMember,
        'isAutoTjekMember': value.isAutoTjekMember,
        'offersToyotaRelax': value.offersToyotaRelax,
        'offersAutoconcept': value.offersAutoconcept,
        'useAutoconceptOnAllVehicles': value.useAutoconceptOnAllVehicles,
        'offersFragus': value.offersFragus,
        'useFragusOnAllVehicles': value.useFragusOnAllVehicles,
        'profileType': UserProfileTypeToJSON(value.profileType),
        'isOpenNow': value.isOpenNow,
        'partnerSantander': value.partnerSantander,
        'partnerClever': value.partnerClever,
        'partnerOK': value.partnerOK,
        'partnerFragus': value.partnerFragus,
        'partnerCarGarantie': value.partnerCarGarantie,
        'partnerCodan': value.partnerCodan,
        'testDriveEnabled': value.testDriveEnabled,
        'testDriveAsPrimaryCTA': value.testDriveAsPrimaryCTA,
        'testDriveAsCTA': value.testDriveAsCTA,
        'writeEnabled': value.writeEnabled,
        'writeAsPrimaryCTA': value.writeAsPrimaryCTA,
        'writeAsCTA': value.writeAsCTA,
        'writeCTAButtonText': value.writeCTAButtonText,
        'callEnabled': value.callEnabled,
        'callAsPrimaryCTA': value.callAsPrimaryCTA,
        'callAsCTA': value.callAsCTA,
        'leasingOfferEnabled': value.leasingOfferEnabled,
        'leasingOfferAsPrimaryCTA': value.leasingOfferAsPrimaryCTA,
        'leasingOfferAsCTA': value.leasingOfferAsCTA,
        'tradeEnabled': value.tradeEnabled,
        'seezEnabled': value.seezEnabled,
    };
}

